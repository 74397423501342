@import url("https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600&display=swap");

* {
  font-family: "Raleway";
  overflow-x: none;
}

.body {
  background-color: rgba(27, 27, 27, 1);
}

.particles {
  position: absolute;
  width: 100%;
  height: 90vh;
  background: rgb(66, 66, 66);
  background: linear-gradient(
    180deg,
    rgba(66, 66, 66, 1) 0%,
    rgba(27, 27, 27, 1) 80%
  );
}

#codebyck {
  max-width: 900px;
  stroke: rgb(0, 255, 213);
  stroke-linejoin: round;
  stroke-width: 2.5px;
}

.svgWrapper {
  height: 320px;
  overflow: hidden;
}

@media screen and (max-width: 630px) {
  #codebyck,
  .svgWrapper {
    display: none;
    transition: 0.2s ease-in;
    /* padding-top: 190px; */
    /* stroke-width: 8px; */
  }
}

/* !===={ Navbar }===! */
.navbar {
  background: rgba(255, 255, 255, 0);
  padding: 0 !important;
}

@media (max-width: 375px) {
  .topNav {
    margin: 0 !important;
    padding-right: 0 !important;
    padding-left: 10px !important;
  }
}

.navLink {
  color: rgb(212, 212, 212) !important;
  font-weight: 600;
}

.navLink:hover {
  color: rgb(212, 212, 212) !important;
  color: rgb(0, 255, 213) !important;
  cursor: pointer !important;
  transition-duration: 0.9s !important;
  text-decoration: none !important;
}

.navBrand {
  color: white;
  font-size: 40px;
  font-weight: 600;
}

/* !===={ Hero }===! */

.hero-container {
  height: 90vh;
  width: 100%;
}

.hero {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-bold {
  color: rgb(0, 255, 213);
  font-weight: 600;
}

.down-Arrow {
  color: rgba(255, 255, 255, 0.164);
  position: absolute;
  top: 90%;
  left: 49%;
  font-size: 50px;
}

@media (max-width: 375px) {
  .down-Arrow {
    left: 45%;
  }

  figure:active img {
    opacity: 0.1;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
  }

  figure:active figcaption {
    opacity: 1;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
  }
}

.down-Arrow:hover {
  color: rgba(255, 255, 255, 0.466);
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

/* !===={ About }===! */

.about-text {
  color: rgb(214, 214, 214);
  font-size: 1.2em;
  font-weight: 400;
}

.about-bg {
  padding-top: 20px;
  background-color: rgba(27, 27, 27, 1);
  width: 100%;
}

.githubLogo {
  color: rgb(212, 212, 212);
  font-size: 40px;
  margin-right: 20px;
}

.contact-links {
  color: rgb(99, 99, 99);
  font-size: 40px;
  margin-right: 20px;
}

.contact-links:hover {
  transition-duration: 0.9s;
  color: rgb(0, 174, 255);
  cursor: pointer;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.githubLogo:hover {
  transition-duration: 0.9s;
  font-size: 41px;
  color: rgb(0, 174, 255);
  cursor: pointer;
}

.linkedinLogo {
  color: rgb(212, 212, 212);
  font-size: 40px;
}

.linkedinLogo:hover {
  transition-duration: 0.9s;
  font-size: 41px;
  color: rgb(0, 174, 255);
  cursor: pointer;
}

.card {
  background: rgba(95, 95, 95, 0.11) !important;
  border-radius: 20px !important;
  border-color: rgba(255, 255, 255, 0.178) !important;
  /* border-width: 2px !important; */
  border: none !important;
  color: white;
  margin-bottom: 30px;
  /* box-shadow: 10px 10px 50px rgba(255, 255, 255, 0.021); */
}

.card-body {
  flex: 1 1 auto !important;
  /* padding: 1.25rem; */
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
}

.frontStack {
  overflow-y: hidden !important;
}

.list-group-item {
  background: none !important;
  border: none !important;
  color: white;
  padding: 0.75rem 0 !important;
}

.tech-text {
  font-weight: 300;
  color: rgb(201, 201, 201);
}

.stack-text {
  font-weight: 500;
  color: aquamarine;
}

.contact-text {
  font-weight: 500;
  color: #1c1c1c;
}

hr {
  border: 1px solid rgba(207, 207, 207, 0.11) !important;
  border-radius: 100px !important;
  width: 50%;
}

.portfolio-img {
  width: 100%;
  border-radius: 20px;
}

figcaption {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  color: #fff;
  padding: 0 1rem;
  display: flex !important;
  flex-direction: column;
}

figure:hover img {
  opacity: 0.1;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
  /* padding: 0 1rem; */
}

figure:hover figcaption {
  opacity: 1;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
  padding: 0 1rem;
}

.btn-project {
  color: white !important;
  border-radius: 20px !important;
  background-color: none !important;
  border-color: rgb(0, 255, 213) !important;
}

.btn-project:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(0, 255, 213, 0.301) !important;
  border-color: rgb(0, 255, 213) !important;
  cursor: pointer !important;
}

.btn-launch {
  color: white !important;
  border-radius: 20px !important;
  background-color: none !important;
  border-color: rgb(212, 0, 0) !important;
}

.btn-launch:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(212, 0, 0) !important;
  border-color: rgb(212, 0, 0) !important;
  cursor: pointer !important;
}

.contact-section {
  background: rgb(204, 204, 204);
  clip-path: polygon(50% 75px, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 0);
  padding-top: 75px;
}

.contact-text {
  font-weight: 400;
  color: rgba(27, 27, 27, 1);
}

.designSvg,
.front-endSvg,
.back-endSvg {
  margin-bottom: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(71, 71, 71);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 151, 126);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 255, 213);
  /* background:rgb(0, 151, 126); */
}
